import React, { useState, useEffect, useContext } from "react"
import Highcharts, { useSerialIds } from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"

import indicatorsAll from "highcharts/indicators/indicators-all"
import annotationsAdvanced from "highcharts/modules/annotations-advanced"
import priceIndicator from "highcharts/modules/price-indicator"
import fullScreen from "highcharts/modules/full-screen"
import stockTools from "highcharts/modules/stock-tools"
import hollowcandlestick from "highcharts/modules/hollowcandlestick"

import "highcharts/css/stocktools/gui.css"
import "highcharts/css/annotations/popup.css"
import { WeightContext } from "../context/WeightProvider"
// "https://code.highcharts.com/modules/exporting.src.js",
// "https://code.highcharts.com/modules/export-data.js"

require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)
require("highcharts/modules/annotations")(Highcharts)

indicatorsAll(Highcharts)
annotationsAdvanced(Highcharts)
priceIndicator(Highcharts)
fullScreen(Highcharts)
stockTools(Highcharts)
hollowcandlestick(Highcharts)

let alertFlag = 1
Highcharts.setOptions({
    lang: {
        thousandsSep: ","
    }
})

function between(x, min, max) {
    return x >= min && x <= max
}

let dummyStockOptions = {
    plotOptions: {
        series: {
            turboThreshold: 1000000
        },
        visible: false
    },
    credits: {
        text: "finsoftai.com",
        href: "http://www.finsoftai.com",
        style: {
            fontSize: 12
        },
        position: {
            align: "right",
            x: -10
        }
    },
    xAxis: [
        {
            title: {
                text: `Time (IST)`,
                style: { fontWeight: "bold", fontSize: "13px" }
            }
        }
    ],
    yAxis: [
        {
            top: "0%",
            height: "60%",
            offset: 0,
            opposite: false,
            title: {
                text: "Sentiment Score",
                style: { fontWeight: "bold", fontSize: "13px" }
            },
            plotBands: [
                {
                    color: "#FCFFC5",
                    from: -0.2,
                    to: 0.2
                },
                {
                    color: "rgba(146, 251, 122, 0.4)",
                    from: 0.2,
                    to: 0.6
                },
                {
                    color: "rgba(92, 200, 59, 0.4)",
                    from: 0.6,
                    to: 1
                },
                {
                    color: "rgba(236, 112, 107, 0.4)",
                    from: -0.2,
                    to: -0.6
                },
                {
                    color: "rgba(234, 51, 35, 0.4)",
                    from: -0.6,
                    to: -1
                }
            ],

            min: -1,
            max: 1,
            tickInterval: 0.5,
            alignTicks: false,
            gridLineWidth: 0,
            showLastLabel: true
        },
        {
            labels: {
                align: "left",
                formatter: function () {
                    return Highcharts.numberFormat(this.value, 2)
                }
            },
            top: "0%",
            height: "60%",
            offset: 0,
            title: {
                text: "LTP/OHLC",
                style: { fontWeight: "bold", fontSize: "13px" }
            },

            alignTicks: false,
            gridLineWidth: 0,
            showLastLabel: true
        },
        {
            opposite: false,
            top: "60%",
            height: "10%",
            offset: 0,
            title: {
                text: "News Volume",
                style: { fontWeight: "bold", fontSize: "13px" }
            },
            resize: { enabled: true }
        },
        {
            labels: { align: "left" },
            top: "70%",
            height: "10%",
            offset: 0,
            title: {
                text: "Stocktwits Volume",
                style: { fontWeight: "bold", fontSize: "13px" }
            },
            resize: { enabled: true }
        },
        // { labels: { align: "left" }, top: "%", height: "10%", offset: 0 },
        // {
        //   opposite: false,
        //   top: "80%",
        //   height: "10%",
        //   offset: 0,
        //   title: {
        //     text: "GTrends Volume",
        //     style: { fontWeight: "bold", fontSize: "13px" },
        //   },
        //   resize: { enabled: true },
        // },
        {
            opposite: false,
            top: "90%",
            height: "10%",
            offset: 0,
            title: {
                text: "Trading Volume",
                style: { fontWeight: "bold", fontSize: "13px" }
            },
            resize: { enabled: true }
        },
        {
            title: {
                text: ""
            },
            opposite: true,
            // startOnTick: false,
            // endOnTick: false,
            showEmpty: true
        }
    ],
    exporting: {
        fallbackToExportServer: false,
        enabled: true,
        allowHTML: true,
        buttons: {
            contextButton: {
                menuItems: [
                    "printChart",
                    "downloadPNG",
                    "downloadJPEG",
                    "downloadPDF",
                    "downloadSVG",
                    "downloadCSV",
                    "downloadJSON"
                ]
            }
        },
        csv: {
            decimalPoint: ".",
            dateFormat: "%Y-%m-%dT%H:%M:%SZ"
        }
    },
    rangeSelector: {
        inputEnabled: true,
        allButtonsEnabled: true,
        inputStyle: {
            pointerEvents: "none",
            cursor: "default",
            fontSize: "16px",
            fontWeight: "600"
        },
        selected: 3,
        buttons: [
            {
                type: "day",
                count: 1,
                text: "1D"
                // offsetMin: 24 * 3600 * 1000
            },
            {
                type: "day",
                count: 7,
                text: "1W"
                // offsetMin: 24 * 3600 * 1000
            },
            {
                type: "day",
                count: 14,
                text: "2W"
                // offsetMin: 24 * 3600 * 1000
            },
            {
                type: "month",
                count: 1,
                text: "1M",
                offsetMin: 24 * 3600 * 1000
            },
            {
                type: "month",
                count: 2,
                text: "2M",
                offsetMin: 24 * 3600 * 1000
            },
            {
                type: "month",
                count: 3,
                text: "3M"
            }
        ]
    },
    navigator: {
        enabled: false
    },
    legend: {
        enabled: true,
        align: "center",
        layout: "horizontal",
        verticalAlign: "top",
        itemStyle: {
            fontSize: "14px"
        },
        x: 45
    },
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 800
                },
                chartOptions: {
                    rangeSelector: {
                        inputEnabled: false,
                        enabled: false
                    }
                }
            }
        ]
    },
    labels: {
        formatter: function () {
            return Highcharts.numberFormat(this.value, 2)
        }
    },
    tooltip: {
        shape: "square",
        headerShape: "callout",
        borderWidth: 0,
        backgroundColor: "rgba(255,255,255,0.7)",
        shadow: false,
        valueDecimals: 2,
        changeDecimals: 2,
        useHTML: true,
        style: {
            fontSize: "16px"
            // pointerEvents: 'auto'
        },
        positioner: function (width, height, point) {
            var chart = this.chart,
                position

            if (point.isHeader) {
                position = {
                    x: Math.max(
                        // Left side limit
                        chart.plotLeft,
                        Math.min(
                            point.plotX + chart.plotLeft - width / 2,
                            // Right side limit
                            chart.chartWidth - width - chart.marginRight
                        )
                    ),
                    y: point.plotY
                }
            } else {
                position = {
                    x: point.series.chart.plotLeft,
                    y: point.series.yAxis.top - chart.plotTop
                }
            }

            return position
        }
    },
    series: []
}

const Graphs = (props) => {
    const [series, setSeries] = useState([])
    const [stockOptions, setStockOptions] = useState(dummyStockOptions)
    const { weights } = useContext(WeightContext)
    const [volGraphHeights, setGraphHeights] = useState({
        st: 0,
        news: 0,
        av: 0
    })

    useEffect(() => {
        let temp = []

        //ALPHAVANTAGE
        const avData = props.avData
        let s_ohlc = []
        let s_ltp = []
        let s_v = []

        if (Object.keys(avData).length > 0) {
            let len = avData.length

            for (let i = 0; i < len; i++) {
                let t_data = avData[i]
                s_ohlc.push({
                    x: t_data["Timestamp"],
                    open: parseFloat(t_data["Open"]),
                    high: parseFloat(t_data["High"]),
                    low: parseFloat(t_data["Low"]),
                    close: parseFloat(t_data["Close"]),
                    color:
                        parseFloat(t_data["Open"]) -
                            parseFloat(t_data["Close"]) >
                        0
                            ? "green"
                            : "#8A0707"
                })
                s_ltp.push([t_data["Timestamp"], parseFloat(t_data["Close"])])
                s_v.push([t_data["Timestamp"], parseFloat(t_data["Volume"])])
            }
            temp.push({
                name: "OHLC",
                id: "hollowcandlestick",
                data: s_ohlc,
                color: "#ff8100",
                type: "hollowcandlestick",
                visible: false,
                yAxis: 1,
                color: "#ff2400",
                lineColor: "#ff2400",
                upColor: "#35bd00",
                upLineColor: "#35bd00"
            })
            temp.push({
                name: "LTP",
                id: "ltp",
                data: s_ltp,
                color: "#2db0d8",
                type: "line",
                visible: s_ltp.length > 0,
                yAxis: 1
            })
            temp.push({
                name: "Volume",
                id: "volume",
                color: "#f1de07",
                visible: s_v.length > 0,
                data: s_v,
                yAxis: 5,
                type: "column"
            })
        }

        //NEWS
        const ibmNewsData = props.newsData
        let norm_sent = []
        let raw_sent = []
        let news_vol = []
        let max_list = []
        let title_text = props.newsToggle

        if (Object.keys(ibmNewsData).length > 0) {
            let len = 1
            try {
                len = parseFloat(ibmNewsData["norm_sentiment"][title_text])
                    ? ibmNewsData["norm_sentiment"][title_text].length
                    : 0

                for (let i = 0; i < len; i++) {
                    let data = ibmNewsData["norm_sentiment"][title_text][i]
                    norm_sent.push([
                        data["Timestamp"],
                        parseFloat(data["norm_sentiment"])
                    ])
                }
                len = ibmNewsData["news_volume"]
                    ? ibmNewsData["news_volume"].length
                    : 0
                for (let i = 0; i < len; i++) {
                    let data = ibmNewsData["news_volume"][i]
                    news_vol.push([
                        data["Timestamp"],
                        parseFloat(data["volume"])
                    ])
                    max_list.push(parseFloat(data["volume"]))
                }
                len = ibmNewsData["raw_sentiment"][title_text]
                    ? ibmNewsData["raw_sentiment"][title_text].length
                    : 0

                for (let i = 0; i < len; i++) {
                    let data = ibmNewsData["raw_sentiment"][title_text][i]
                    raw_sent.push([
                        data["Timestamp"],
                        parseFloat(data["Sentiment_score"])
                    ])
                }
            } catch (err) {
                console.log(err)
            }

            temp.push({
                name: "Positive News",
                type: "flags",
                allowOverlapX: true,
                data: ibmNewsData[title_text]
                    ? ibmNewsData[title_text]["positive"]
                    : [],
                onSeries: "ltp",
                shape: "squarepin",
                width: 20,
                color: "#3b883f",
                visible: !!ibmNewsData[title_text] || false,
                fillColor: "rgba(59, 136, 63 ,0.65)",
                style: { color: "white" },
                states: { hover: { fillColor: "#3b883f" } }
            })
            temp.push({
                name: "Negative News",
                type: "flags",
                allowOverlapX: true,
                data: ibmNewsData[title_text]
                    ? ibmNewsData[title_text]["negative"]
                    : [],
                onSeries: "ltp",
                shape: "squarepin",
                width: 20,
                color: "#ca3b33",
                visible: !!ibmNewsData[title_text] || false,
                fillColor: "rgba(202, 59, 51,0.65)",
                style: { color: "white" },
                states: { hover: { fillColor: "#ca3b33" } }
            })
            temp.push({
                name: "Neutral News",
                type: "flags",
                allowOverlapX: true,
                data: ibmNewsData[title_text]
                    ? ibmNewsData[title_text]["neutral"]
                    : [],
                onSeries: "ltp",
                shape: "squarepin",
                width: 20,
                // onKey: "high",
                color: "#f8cc47",
                visible: !!ibmNewsData[title_text] || false,
                fillColor: "rgba(248, 204, 71,0.65)",
                style: { color: "white" },
                states: { hover: { fillColor: "#f8cc47" } }
            })
            temp.push({
                name: "Normalized News Sentiment (" + title_text + ")",
                id: "norm_st_news",
                data: norm_sent,
                color: "#8c2e9a",
                type: "line",
                yAxis: 0,
                ordinal: true,
                visible: false,
                tooltip: {
                    valueDecimals: 2
                }
            })
            temp.push({
                name: "Raw Sentiment News (" + title_text + ")",
                id: "raw_st_news",
                data: raw_sent,
                color: "#813791",
                type: "line",
                yAxis: 0,
                ordinal: true,
                visible: false,
                tooltip: {
                    valueDecimals: 2
                }
            })
            !weights.edgar &&
                temp.push({
                    name: "News Volume",
                    id: "newsVolume",
                    data: news_vol,
                    visible: !!news_vol || false,
                    yAxis: 2,
                    color: "#740285",
                    type: "column"
                })
            setGraphHeights((prevState) => {
                return {
                    ...prevState,
                    news:
                        max_list.length > 0
                            ? max_list.reduce((a, b) => {
                                  return Math.max(a, b)
                              })
                            : 10
                }
            })
        }

        // Stocktwits
        let stocktwitsData = props.stocktwitsData
        let norm_sent_st = []
        let raw_sent_st = []
        let st_vol = []
        let max_list_st = []

        if (Object.keys(stocktwitsData).length > 0) {
            let len = stocktwitsData["norm_sentiment"]
                ? stocktwitsData["norm_sentiment"].length
                : 0
            for (let i = 0; i < len; i++) {
                let data = stocktwitsData["norm_sentiment"][i]
                norm_sent_st.push([
                    data["Timestamp"],
                    parseFloat(data["norm_sentiment"])
                ])
            }
            len = stocktwitsData["raw"] ? stocktwitsData["raw"].length : 0
            for (let i = 0; i < len; i++) {
                let data = stocktwitsData["raw"][i]
                raw_sent_st.push([
                    data["Timestamp"],
                    parseFloat(data["Sentiment_score"])
                ])
            }

            len = stocktwitsData["st_vol"] ? stocktwitsData["st_vol"].length : 0
            for (let i = 0; i < len; i++) {
                let data = stocktwitsData["st_vol"][i]
                st_vol.push([data["Timestamp"], parseFloat(data["volume"])])
                max_list_st.push(parseFloat(data["volume"]))
            }

            temp.push({
                name: "Positive Stocktwits",
                type: "flags",
                allowOverlapX: true,
                data: stocktwitsData["positive"],
                onSeries: "ltp",
                shape: "circlepin",
                width: 16,
                color: "#3b883f",
                visible:
                    (stocktwitsData &&
                        stocktwitsData["positive"] &&
                        stocktwitsData["positive"].length > 0) ||
                    false,
                fillColor: "rgba(59, 136, 63 ,0.65)",
                style: { color: "white" },
                states: { hover: { fillColor: "#3b883f" } }
            })
            temp.push({
                name: "Negative Stocktwits",
                type: "flags",
                allowOverlapX: true,
                data: stocktwitsData["negative"],
                onSeries: "ltp",
                shape: "circlepin",
                width: 16,
                color: "#ca3b33",
                visible:
                    (stocktwitsData &&
                        stocktwitsData["negative"] &&
                        stocktwitsData["negative"].length > 0) ||
                    false,
                fillColor: "rgba(202, 59, 51,0.65)",
                style: { color: "white" },
                states: { hover: { fillColor: "#ca3b33" } }
            })
            temp.push({
                name: "Neutral Stocktwits",
                type: "flags",
                allowOverlapX: true,
                data: stocktwitsData["neutral"],
                onSeries: "ltp",
                shape: "circlepin",
                width: 16,
                color: "#f8cc47",
                visible:
                    (stocktwitsData &&
                        stocktwitsData["neutral"] &&
                        stocktwitsData["neutral"].length > 0) ||
                    false,
                fillColor: "rgba(248, 204, 71,0.65)",
                style: { color: "white" },
                states: { hover: { fillColor: "#f8cc47" } }
            })
            temp.push({
                name: "Normalized Stocktwits Sentiment",
                id: "norm_st",
                data: norm_sent_st,
                color: "#ff2dfc",
                type: "line",
                ordinal: true,
                visible: false,
                yAxis: 0,
                tooltip: {
                    valueDecimals: 2
                }
            })
            temp.push({
                name: "Raw Stocktwits Sentiment",
                id: "raw_st",
                data: raw_sent_st,
                color: "#285bad",
                type: "line",
                ordinal: true,
                visible: false,
                yAxis: 0,
                tooltip: {
                    valueDecimals: 2
                }
            })
            !weights.edgar &&
                temp.push({
                    name: "Stocktwits Volume",
                    id: "stocktwits_Volume",
                    data: st_vol,
                    visible: !!st_vol.length || false,
                    yAxis: 3,
                    color: "#ff2dfc",
                    type: "column"
                })
        }

        //Reddit
        let redditData = props.redditData
        let norm_reddit_st_new = []
        let reddit_vol = []

        if (Object.keys(redditData).length > 0) {
            let len = redditData["norm_sentiment"]
                ? redditData["norm_sentiment"].length
                : 0
            for (let i = 0; i < len; i++) {
                let data = redditData["norm_sentiment"][i]
                norm_reddit_st_new.push([
                    data["Timestamp"],
                    parseFloat(data["norm_sentiment"])
                ])
            }
            len = redditData["st_vol"] ? redditData["st_vol"].length : 0
            for (let i = 0; i < len; i++) {
                let data = redditData["st_vol"][i]
                reddit_vol.push([data["Timestamp"], parseFloat(data["volume"])])
            }
            temp.push({
                name: "Positive Reddit",
                type: "flags",
                allowOverlapX: true,
                data: redditData["positive"],
                visible: !!redditData || false,
                onSeries: "ltp",
                shape: "circlepin",
                width: 16,
                color: "#3b883f",
                fillColor: "rgba(59, 136, 63 ,0.65)",
                style: { color: "white" },
                states: { hover: { fillColor: "#3b883f" } }
            })
            temp.push({
                name: "Negative Reddit",
                type: "flags",
                allowOverlapX: true,
                data: redditData["negative"],
                visible: !!redditData || false,
                onSeries: "ltp",
                shape: "circlepin",
                width: 16,
                color: "#ca3b33",
                fillColor: "rgba(202, 59, 51,0.65)",
                style: { color: "white" },
                states: { hover: { fillColor: "#ca3b33" } }
            })
            temp.push({
                name: "Neutral Reddit",
                type: "flags",
                allowOverlapX: true,
                data: redditData["neutral"],
                visible: !!redditData || false,
                onSeries: "ltp",
                shape: "circlepin",
                width: 16,
                color: "#f8cc47",
                fillColor: "rgba(248, 204, 71,0.65)",
                style: { color: "white" },
                states: { hover: { fillColor: "#f8cc47" } }
            })
            temp.push({
                name: "Normalized Reddit Sentiment",
                id: "norm_reddit",
                data: norm_reddit_st_new,
                color: "#65000b",
                type: "line",
                visible: false,
                yAxis: 0,
                tooltip: {
                    valueDecimals: 2
                }
            })
            !weights.edgar &&
                temp.push({
                    name: "Reddit Volume",
                    id: "reddit_Volume",
                    data: reddit_vol,
                    visible: !!reddit_vol || false,
                    yAxis: 4,
                    color: "#CC5500",
                    type: "column"
                })
        }

        // Edgar
        let edgarData = props.edgarData
        let norm_edgar_st_new = []
        let edgar_vol = []

        if (Object.keys(edgarData).length > 0) {
            let len = edgarData["norm_sentiment"]
                ? edgarData["norm_sentiment"].length
                : 0
            for (let i = 0; i < len; i++) {
                let data = edgarData["norm_sentiment"][i]
                norm_edgar_st_new.push([
                    data["Timestamp"],
                    data["norm_sentiment"]
                ])
            }
            len = edgarData["st_vol"] ? edgarData["st_vol"].length : 0
            for (let i = 0; i < len; i++) {
                let data = edgarData["st_vol"][i]
                edgar_vol.push([data["Timestamp"], data["volume"]])
            }

            temp.push({
                name: "Positive Edgar",
                type: "flags",
                allowOverlapX: true,
                data: edgarData["positive"],
                visible: !!edgarData || false,
                onSeries: "ltp",
                shape: "circlepin",
                width: 16,
                color: "#3b883f",
                fillColor: "rgba(59, 136, 63 ,0.65)",
                style: { color: "white" },
                states: { hover: { fillColor: "#3b883f" } }
            })
            temp.push({
                name: "Negative Edgar",
                type: "flags",
                allowOverlapX: true,
                data: edgarData["negative"],
                visible: !!edgarData || false,
                onSeries: "ltp",
                shape: "circlepin",
                width: 16,
                color: "#ca3b33",
                fillColor: "rgba(202, 59, 51,0.65)",
                style: { color: "white" },
                states: { hover: { fillColor: "#ca3b33" } }
            })
            temp.push({
                name: "Neutral Edgar",
                type: "flags",
                allowOverlapX: true,
                data: edgarData["neutral"],
                visible: !!edgarData || false,
                onSeries: "ltp",
                shape: "circlepin",
                width: 16,
                color: "#f8cc47",
                fillColor: "rgba(248, 204, 71,0.65)",
                style: { color: "white" },
                states: { hover: { fillColor: "#f8cc47" } }
            })
        }

        setSeries(temp)
    }, [
        props.avData,
        props.newsData,
        props.stocktwitsData,
        props.redditData,
        props.edgarData
    ])

    useEffect(() => {
        let xLabel = ``
        if (props.query.length > 0) {
            xLabel = props.query.query_name
        }

        let yAxisConfig = []

        if (!weights.edgar) {
            yAxisConfig = [
                {
                    top: "0%",
                    height: "60%",
                    offset: 0,
                    opposite: false,
                    title: {
                        text: "Sentiment Score",
                        style: { fontWeight: "bold", fontSize: "13px" }
                    },
                    plotBands: [
                        { color: "#FCFFC5", from: -0.2, to: 0.2 },
                        {
                            color: "rgba(146, 251, 122, 0.3)",
                            from: 0.2,
                            to: 0.6
                        },
                        { color: "rgba(92, 200, 59, 0.3)", from: 0.6, to: 1 },
                        {
                            color: "rgba(236, 112, 107, 0.3)",
                            from: -0.2,
                            to: -0.6
                        },
                        { color: "rgba(234, 51, 35, 0.3)", from: -0.6, to: -1 }
                    ],
                    min: -1,
                    max: 1,
                    tickInterval: 0.5,
                    alignTicks: false,
                    gridLineWidth: 0,
                    showLastLabel: true
                },
                {
                    labels: { align: "left" },
                    top: "0%",
                    height: "60%",
                    offset: 0,
                    title: {
                        text: "LTP/OHLC",
                        style: { fontWeight: "bold", fontSize: "13px" }
                    },
                    alignTicks: false,
                    gridLineWidth: 0,
                    showLastLabel: true
                },
                {
                    opposite: false,
                    top: "60%",
                    height: "10%",
                    offset: 0,
                    title: {
                        text: "News Volume",
                        style: { fontWeight: "bold", fontSize: "13px" }
                    }
                },
                {
                    labels: { align: "left" },
                    top: "70%",
                    height: "10%",
                    offset: 0,
                    title: {
                        text: "Stocktwits Volume",
                        style: { fontWeight: "bold", fontSize: "13px" }
                    }
                },
                {
                    opposite: false,
                    top: "80%",
                    height: "10%",
                    offset: 0,
                    title: {
                        text: "Reddit Volume",
                        style: { fontWeight: "bold", fontSize: "13px" }
                    },
                    resize: { enabled: true }
                },
                {
                    opposite: true,
                    top: "90%",
                    height: "10%",
                    offset: 0,
                    title: {
                        text: "Trading Volume",
                        style: { fontWeight: "bold", fontSize: "13px" }
                    },
                    resize: { enabled: true }
                }
            ]
        } else {
            yAxisConfig = [
                {
                    top: "0%",
                    height: "60%",
                    offset: 0,
                    opposite: false,
                    title: {
                        text: "Sentiment Score",
                        style: { fontWeight: "bold", fontSize: "13px" }
                    },
                    plotBands: [
                        { color: "#FCFFC5", from: -0.2, to: 0.2 },
                        {
                            color: "rgba(146, 251, 122, 0.3)",
                            from: 0.2,
                            to: 0.6
                        },
                        { color: "rgba(92, 200, 59, 0.3)", from: 0.6, to: 1 },
                        {
                            color: "rgba(236, 112, 107, 0.3)",
                            from: -0.2,
                            to: -0.6
                        },
                        { color: "rgba(234, 51, 35, 0.3)", from: -0.6, to: -1 }
                    ],
                    min: -1,
                    max: 1,
                    tickInterval: 0.5,
                    alignTicks: false,
                    gridLineWidth: 0,
                    showLastLabel: true
                },
                {
                    labels: { align: "left" },
                    top: "0%",
                    height: "60%",
                    offset: 0,
                    title: {
                        text: "LTP/OHLC",
                        style: { fontWeight: "bold", fontSize: "13px" }
                    },
                    alignTicks: false,
                    gridLineWidth: 0,
                    showLastLabel: true
                },
                {},
                {},
                {},
                {
                    opposite: true,
                    top: "90%",
                    height: "10%",
                    offset: 0,
                    title: {
                        text: "Trading Volume",
                        style: { fontWeight: "bold", fontSize: "13px" }
                    },
                    resize: { enabled: true }
                }
            ]
        }

        if (series && series.length > 0) {
            setStockOptions({
                plotOptions: {
                    series: {
                        turboThreshold: 1000000,
                        visible: false,
                        ohlc: { lineWidth: 2 },
                        hollowcandlestick: { color: "#ff2400" }
                    }
                },
                credits: {
                    text: "finsoftai.com",
                    href: 'javascript:window.open("http://www.finsoftai.com/", "_blank")',
                    style: { fontSize: 12 },
                    position: { align: "right", x: -10 }
                },
                xAxis: [
                    {
                        title: {
                            text: `Time (IST)`,
                            style: { fontWeight: "bold", fontSize: "13px" }
                        }
                    }
                ],
                yAxis: yAxisConfig,
                rangeSelector: {
                    inputEnabled: true,
                    allButtonsEnabled: true,
                    inputStyle: {
                        pointerEvents: "none",
                        cursor: "default",
                        fontSize: "16px",
                        fontWeight: "600"
                    },
                    selected: 7,
                    buttons: [
                        { type: "day", count: 1, text: "1D" },
                        { type: "day", count: 7, text: "1W" },
                        { type: "day", count: 14, text: "2W" },
                        {
                            type: "month",
                            count: 1,
                            text: "1M",
                            offsetMin: 24 * 3600 * 1000
                        },
                        {
                            type: "month",
                            count: 2,
                            text: "2M",
                            offsetMin: 24 * 3600 * 1000
                        },
                        { type: "month", count: 3, text: "3M" },
                        { type: "all", count: 3, text: "ALL" }
                    ]
                },
                navigator: { enabled: false },
                legend: {
                    enabled: true,
                    align: "center",
                    layout: "horizontal",
                    verticalAlign: "top",
                    itemStyle: { fontSize: "14px" },
                    x: 45
                },
                responsive: {
                    rules: [
                        {
                            condition: { maxWidth: 800 },
                            chartOptions: {
                                rangeSelector: {
                                    inputEnabled: false,
                                    enabled: false
                                }
                            }
                        }
                    ]
                },
                labels: {
                    formatter: function () {
                        return Highcharts.numberFormat(this.value, 2)
                    }
                },
                tooltip: {
                    shape: "square",
                    headerShape: "callout",
                    borderWidth: 0,
                    stickOnContact: true,
                    backgroundColor: "rgba(255,255,255,0.7)",
                    shadow: false,
                    valueDecimals: 2,
                    changeDecimals: 2,
                    useHTML: true,
                    style: { fontSize: "14px" }
                },
                chart: {
                    height: 800
                },
                exporting: {
                    filename: `${props.query.ticker}_${props.query.sdate}_to_${props.query.edate}`,
                    fallbackToExportServer: false,
                    enabled: true,
                    allowHTML: true,
                    buttons: {
                        contextButton: {
                            menuItems: [
                                "printChart",
                                "downloadPNG",
                                "downloadJPEG",
                                "downloadPDF",
                                "downloadSVG",
                                "downloadCSV",
                                "downloadJSON"
                            ]
                        }
                    },
                    csv: {
                        decimalPoint: ".",
                        dateFormat: "%Y-%m-%dT%H:%M:%SZ"
                    }
                },
                series: series
            })
        }
    }, [series])

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={"stockChart"}
                options={stockOptions}
                key={JSON.stringify(stockOptions)}
            />
        </div>
    )
}

export default Graphs
