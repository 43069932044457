// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 5%;
  }

  @media screen and (max-width: 600px) {
    .reset_box {
      background: radial-gradient(circle, rgba(146,201,245,1) 26%, rgba(128,194,244,1) 52%, rgba(31,143,225,1) 88%) !important;
      padding-right: 0px  !important;
      align-items: center !important;
    }
  }
  .reset__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color:  rgba(169, 169, 169, 0);
    padding: 20px;
    padding-top: 30px;
    border-radius: 15px;
    font-family: Poppins;
  line-height: 1.8;

  }
  .reset__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    background-color: whitesmoke;
    border: solid 0px;
    border-radius: 5px;
    color:black;
  }
  .reset__btn {
    width: 223px;
    height: 50px;
    background: #DDEDE1;
    border-radius: 5px;
    margin:auto;
    margin-bottom: 5px;
    color: #858585;
    border: solid 0px;
  
  }
  .reset div {
    margin-top: 7px;
  }
  .inline-hr {
    display: inline-block;
    min-width: 25%;
    max-width: 40%;
    color: #858585;
    margin: 0 5px;
  }`, "",{"version":3,"sources":["webpack://./src/components/static/Reset.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,iBAAiB;EACjB;;EAEA;IACE;MACE,wHAAwH;MACxH,8BAA8B;MAC9B,8BAA8B;IAChC;EACF;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yCAAyC;IACzC,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,oBAAoB;EACtB,gBAAgB;;EAEhB;EACA;IACE,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,4BAA4B;IAC5B,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;EACb;EACA;IACE,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,cAAc;IACd,iBAAiB;;EAEnB;EACA;IACE,eAAe;EACjB;EACA;IACE,qBAAqB;IACrB,cAAc;IACd,cAAc;IACd,cAAc;IACd,aAAa;EACf","sourcesContent":[".reset {\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n  align-items: center;\n  justify-content: end;\n  padding-right: 5%;\n  }\n\n  @media screen and (max-width: 600px) {\n    .reset_box {\n      background: radial-gradient(circle, rgba(146,201,245,1) 26%, rgba(128,194,244,1) 52%, rgba(31,143,225,1) 88%) !important;\n      padding-right: 0px  !important;\n      align-items: center !important;\n    }\n  }\n  .reset__container {\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n    background-color:  rgba(169, 169, 169, 0);\n    padding: 20px;\n    padding-top: 30px;\n    border-radius: 15px;\n    font-family: Poppins;\n  line-height: 1.8;\n\n  }\n  .reset__textBox {\n    padding: 10px;\n    font-size: 18px;\n    margin-bottom: 10px;\n    background-color: whitesmoke;\n    border: solid 0px;\n    border-radius: 5px;\n    color:black;\n  }\n  .reset__btn {\n    width: 223px;\n    height: 50px;\n    background: #DDEDE1;\n    border-radius: 5px;\n    margin:auto;\n    margin-bottom: 5px;\n    color: #858585;\n    border: solid 0px;\n  \n  }\n  .reset div {\n    margin-top: 7px;\n  }\n  .inline-hr {\n    display: inline-block;\n    min-width: 25%;\n    max-width: 40%;\n    color: #858585;\n    margin: 0 5px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
