const moment = require("moment-timezone")
const api = require("../components/api")

const stockList = api.get_tickers()

// Function to generate time queries for different timing modes => providedDate = '2023-11-22'
const getTodayDate = (providedDate) => {
    let date = moment().tz("America/New_York")

    if (providedDate) {
        date = moment(providedDate, "YYYY-MM-DD").tz("America/New_York")
    }

    const cutoffTime = date.set({
        hour: 8,
        minute: 30,
        second: 0,
        millisecond: 0
    })

    let assignedDate

    if (date.isBefore(cutoffTime)) {
        // If the current time is before 8:30 AM ET, use the previous day
        assignedDate = date.subtract(1, "day").clone()
    } else {
        // Otherwise, use the current day
        assignedDate = date.clone()
    }

    return assignedDate.format("YYYY-MM-DDTHH:mm:ssZ")
}

// Create Mapping for Alerts
const getMappedAlertData = (data, key1, key2) => {
    return stockList.map((stock) => {
        const buzz1 = data.find((buzz) => buzz.fields === key1)
        const buzz2 = data.find((buzz) => buzz.fields === key2)
        if (!buzz1 || !buzz2) return

        if (stock === "FB/META") return (buzz1["META"] + buzz2["META"]) / 2

        return (buzz1[stock] + buzz2[stock]) / 2
    })
}

// Generate Pre-market Alerts
const generatePreMarketAlerts = (apiResponse) => {
    const mappedSocialSentimentChange = getMappedAlertData(
        apiResponse.changeSentiment,
        "one_day_st_change_percent",
        "one_day_news_change_percent"
    )
    // one_day_news_change_percent
    const mappedSocialBuzzChange = getMappedAlertData(
        apiResponse.changeBuzz,
        "one_day_st_change_percent",
        "one_day_news_change_percent"
    )

    const preMarketAlerts = mappedSocialSentimentChange
        .map((sentimentChange, index) => {
            const socialBuzzChange = mappedSocialBuzzChange[index]
            const stock = stockList[index]

            if (sentimentChange >= 25 && socialBuzzChange >= 100)
                return { stock, result: "STRONG_BUY" }
            else if (sentimentChange >= 25 && socialBuzzChange >= 50)
                return { stock, result: "BUY" }
            else if (sentimentChange <= -25 && socialBuzzChange >= 100)
                return { stock, result: "STRONG_SELL" }
            else if (sentimentChange <= -25 && socialBuzzChange >= 50)
                return { stock, result: "SELL" }
        })
        .filter((stock) => stock)

    return preMarketAlerts
}

// Generate Momentum Alerts
const generateMomentumAlerts = (apiResponse) => {
    const mappedPrevDaySentiment = getMappedAlertData(
        apiResponse.sentiment,
        "one_day_st",
        "one_day_news"
    )
    const mappedOneWeekSentiment = getMappedAlertData(
        apiResponse.sentiment,
        "one_week_st",
        "one_week_news"
    )
    const mappedTwoWeekSentiment = getMappedAlertData(
        apiResponse.sentiment,
        "two_week_st",
        "two_week_news"
    )
    const mappedOneMonthSentiment = getMappedAlertData(
        apiResponse.sentiment,
        "one_month_st",
        "one_month_news"
    )

    const momentumAlerts = mappedOneWeekSentiment
        .map((oneWeek, index) => {
            const twoWeek = mappedTwoWeekSentiment[index]
            const oneMonth = mappedOneMonthSentiment[index]
            const prevDay = mappedPrevDaySentiment[index]
            const stock = stockList[index]

            // 1.
            if (oneMonth < twoWeek && twoWeek < oneWeek && oneWeek < prevDay) {
                return {
                    stock,
                    result: "STRONG_BUY"
                }
            }
            // 2.
            else if (
                oneMonth > twoWeek &&
                twoWeek > oneWeek &&
                oneWeek > prevDay
            ) {
                return {
                    stock,
                    result: "STRONG_SELL"
                }
            }
            // 3.
            else if (twoWeek < oneWeek && oneWeek < prevDay) {
                return {
                    stock,
                    result: "BUY"
                }
            }
            // 4.
            else if (twoWeek > oneWeek && oneWeek > prevDay) {
                return {
                    stock,
                    result: "SELL"
                }
            }
        })
        .filter((stock) => stock)

    return momentumAlerts
}

// Generate Alerts
const generateAlerts = (apiResponse) => {
    const preMarketAlerts = generatePreMarketAlerts(apiResponse)
    const momentumAlerts = generateMomentumAlerts(apiResponse)

    return {
        preMarketAlerts,
        momentumAlerts
    }
}

module.exports = {
    generatePreMarketAlerts,
    generateMomentumAlerts,
    generateAlerts,
    getTodayDate
}
