import "./App.css"
import QueryBuilder from "./components/QueryBuilder"
import Navbar from "./components/Navbar"
import React, { useEffect, useState } from "react"
import Tables from "./components/Tables"
import Graphs from "./components/Graphs"
import PieCharts from "./components/Piecharts"
import CompositeSent from "./components/CompositeSent"
import { useNavigate } from "react-router-dom"
import Footer from "./components/Footer"
import { auth } from "./components/firebase"
import { useAuthState } from "react-firebase-hooks/auth"
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride"
import fullScreenImg from "./components/static/full_screen_HC.png"
import stocksImg from "./components/static/stocks_HC.png"
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart"
import {
    Container as FContainer,
    Button as FButton
} from "react-floating-action-button"
import axios from "axios"

const OurApp = () => {
    const [user, loading, error] = useAuthState(auth)
    const navigate = useNavigate()
    const [queryOptions, setOptions] = useState([])

    useEffect(() => {
        if (loading) return
        if (!user) return navigate("/")
    }, [user, loading])

    const [query, setQuery] = React.useState({})
    const [savedQuery, setSavedQuery] = React.useState({})
    const [pieChartsData, setPieChartsData] = React.useState({})
    const [compositeSentimentData, setCompositeSentimentData] = React.useState(
        {}
    )
    const [newsTableData, setNewsTableData] = React.useState({
        data: { total: 0, data: [] },
        count: { positive: 0, negative: 0, neutral: 0 },
        count_text: { positive: 0, negative: 0, neutral: 0 }
    })
    const [stocktwitsTableData, setStocktwitsTableData] = React.useState({
        data: { title: { total: 0, data: [] } },
        count: { positive: 0, negative: 0, neutral: 0 },
        count_text: { positive: 0, negative: 0, neutral: 0 }
    })
    const [redditTableData, setRedditTableData] = React.useState({
        data: { title: { total: 0, data: [] } },
        count: { positive: 0, negative: 0, neutral: 0 },
        count_text: { positive: 0, negative: 0, neutral: 0 }
    })
    const [ticker, setTicker] = React.useState("")
    const [avHicData, setAvHicData] = React.useState([])
    const [ibmNewsHicData, setIbmNewsHicData] = React.useState([])
    const [stocktwitsHicData, setStocktwitsHicData] = React.useState([])
    const [redditHicData, setRedditHicData] = React.useState([])
    const [hicData, setHicData] = React.useState([])
    const [newsToggle, setToggle] = React.useState("title")
    const [queryModified, setModifiedState] = React.useState(false)
    const [queryMeta, setQueryMeta] = React.useState({
        name: null,
        version: null,
        sector: null,
        prevVersion: null
    })
    const [compWeights, setWeights] = useState({
        stocktwits: 0,
        news: 0,
        thirdParty: 0,
        gtrends: 0
    })

    const [modalOpen, setModalOpen] = useState(false)

    const [steps, setSteps] = useState([
        {
            target: ".first-step",
            content: (
                <div>
                    <span>
                        Select an existing query from the{" "}
                        <strong>dropdown list.</strong>
                    </span>
                </div>
            ),
            disableBeacon: true
        },

        {
            target: ".query-builder",
            content: (
                <div>
                    <span>
                        Here you can view your selected query or create a{" "}
                        <strong>new query </strong> by following the steps given
                        in the help section.
                    </span>
                </div>
            )
        },
        {
            target: ".third-step",
            content: (
                <div>
                    <span>
                        {" "}
                        <strong>The Debug panel</strong> displays the query in
                        JSON format.
                    </span>
                </div>
            )
        },
        {
            target: ".second-step",
            content: (
                <div>
                    <span>
                        Click to Expand/Collapse the{" "}
                        <strong> Query Builder</strong> section.
                    </span>
                </div>
            )
        },

        {
            target: ".fourth-step",
            content: (
                <div>
                    <span>
                        Select Sentiment based on the{" "}
                        <strong> News Title </strong> or{" "}
                        <strong>News Text</strong>.
                    </span>
                </div>
            )
        },
        {
            target: ".new-step-five",
            content: (
                <div>
                    <span>
                        {" "}
                        <strong>Data Quality (DQ)</strong> filters noise and
                        improves results by scoring social media posts based on
                        credibility and usefulness. Select Sentiment Type based
                        on <strong>DQ</strong> or{" "}
                        <strong>Non DQ sentiment</strong>.
                    </span>
                </div>
            )
        },
        {
            target: ".fifth-step",
            content: (
                <div>
                    <span>
                        Click on this button to <strong> run the query </strong>
                    </span>
                </div>
            )
        },
        {
            target: ".new-step-four",
            content: (
                <div>
                    <span>
                        Click on this button to know{" "}
                        <strong>about SST-Research</strong> and{" "}
                        <strong>about FinSoftAi</strong>
                    </span>
                </div>
            )
        },
        {
            target: ".new-step-one",
            content: (
                <div>
                    <span>
                        Click on this button to{" "}
                        <strong>Configure Screen Layout</strong> to display on
                        main screen
                    </span>
                </div>
            )
        },
        {
            target: ".new-step-two",
            content: (
                <div>
                    <span>
                        Click on the <strong>Set Weights</strong> button to set
                        weights for sources. This panel displays{" "}
                        <strong>Composite Sentiment </strong> of multiple
                        available sources.
                    </span>
                </div>
            )
        },
        {
            target: ".sixth-step",
            content: (
                <div>
                    {" "}
                    <span>
                        This panel displays the <strong>aggregated view</strong>{" "}
                        of Sentiment for each source over the query period. Each
                        source is represented by its individual color coded
                        doughnut, The length of the colored arcs in the circle
                        represent the strength of the strong sell, weak sell,
                        neutral/hold, weak sell and strong sell signals. The
                        doughnuts for the sources that are turned off or
                        currently not supported are greyed.
                    </span>
                </div>
            )
        },
        {
            target: ".seventh-step",
            content: (
                <div>
                    {" "}
                    <span>
                        This panel displays <strong>Sentiment over time</strong>{" "}
                        and <strong>Sentiment Volume over time</strong> for each
                        source over the query period. Use the{" "}
                        <strong>toggle buttons</strong> at the top to{" "}
                        <strong>turn on or turn off items on the chart</strong>{" "}
                        to customize your view. Click{" "}
                        <img src={fullScreenImg} alt=''></img> at the left to
                        view this chart in full scree mode. Use the top left
                        icon <img src={stocksImg} alt=''></img> to create
                        technical indicators based on Normalized sentiment and
                        Sentiment Volume.
                    </span>
                </div>
            )
        },
        {
            target: ".eight-step",
            content: (
                <div>
                    {" "}
                    <span>
                        Displays the <strong>number of documents</strong>{" "}
                        returned by the query
                    </span>
                </div>
            )
        },
        {
            target: ".sorting-step",
            content: (
                <div>
                    {" "}
                    <span>
                        Click to disply sorting options-{" "}
                        <strong>By Relevance</strong> (Most relevant articles
                        related to the query, default), <strong>By Date</strong>{" "}
                        (Most recent article),{" "}
                        <strong>By Sentiment score</strong> (Sentiment value){" "}
                    </span>
                </div>
            )
        },
        {
            target: ".ninth-step",
            content: (
                <div>
                    <span>
                        You can view <strong>documents returned</strong> by the
                        Query which are <strong>color coded</strong> by
                        sentiment.
                    </span>
                </div>
            )
        },
        {
            target: ".tenth-step",
            content: (
                <div>
                    <span>
                        <strong>Download News documents</strong> returned by the
                        Query
                    </span>
                </div>
            )
        },
        {
            target: ".eleventh-step",
            content: (
                <div>
                    <span>
                        <strong>Download Stocktwits documents</strong> returned
                        by the Query
                    </span>
                </div>
            )
        },
        {
            target: ".new-step-three",
            content: (
                <div>
                    <span>
                        Click to show <strong>Trending Dashboards</strong> based
                        on sentiment and social buzz
                    </span>
                </div>
            )
        },

        {
            target: ".twelth-step",
            content: (
                <div>
                    <span>
                        {" "}
                        <strong>Save a Query.</strong> If an existing query is
                        modified then it is saved with a new version number.
                    </span>
                </div>
            )
        },
        {
            target: ".fourteenth-step",
            content: (
                <div>
                    <span>
                        Click on this to <strong>delete the query.</strong>
                    </span>
                </div>
            )
        },
        {
            target: ".thirteenth-step",
            content: (
                <div>
                    <span>
                        Click on this to clear and create a new query using the{" "}
                        <strong>Query Builder.</strong>
                    </span>
                </div>
            )
        }
    ])
    const [run, setRun] = useState(false)
    const [stepIndex, setIndex] = useState(0)
    const [newQueryFlag, setFlag] = useState(false)
    const [screensOnDisplay, setDisplayScreens] = useState({
        composite: true,
        piecharts: true,
        graph: true,
        tables: true
    })

    const [DQToggle, setDQToggle] = React.useState("Sentiment_score")

    const [parameterOption, setParameterOptions] = React.useState("general")

    const handleJoyrideClose = ({ action, index, status, type }) => {
        if (action === "start") {
            setDisplayScreens({
                composite: true,
                piecharts: true,
                graph: true,
                tables: true
            })
        }
        if ([EVENTS.TOUR_END].includes(type) || action === "close") {
            setRun((t) => {
                return false
            })
            setIndex((t) => {
                return 0
            })
        } else if (
            [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)
        ) {
            // Update state to advance the tour
            setIndex((prevState) => {
                return index + (action === ACTIONS.PREV ? -1 : 1)
            })
        }
        if (action === "close" && type === "step:after") {
            setRun((t) => {
                return false
            })

            fetchScreenLayout()
        }
    }

    const fetchScreenLayout = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_DATA_SERVER_API_URL}/layout/${user.uid}`,
                {
                    headers: { "Access-Control-Allow-Origin": "*" }
                }
            )
            if (response.data.status) {
                setDisplayScreens(JSON.parse(response.data.data.config))
            }
        } catch (err) {
            console.log(err)
        }
    }

    const fetchQueryNames = async () => {
        if (!user) return

        try {
            const resp = await axios.get(
                `${process.env.REACT_APP_DATA_SERVER_API_URL}/query/names/${user.uid}`,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Authorization"
                    }
                }
            )
            return resp.data
        } catch (err) {
            console.log("HERE", err)
        }
    }

    const populateOptions = async () => {
        let queryNameList = await fetchQueryNames()

        if (!queryNameList.status) return

        let temp = await queryNameList.data.map((query, index) => {
            return (
                <option
                    value={query.query_name + "||" + query.query_version}
                    style={{
                        fontSize: 24,
                        paaddingTop: 2,
                        paddingBottom: 2,
                        fontFamily: "Verdana, Arial",
                        border: "solid 1px red !important"
                    }}>
                    {query.query_name} (v{query.query_version})
                </option>
            )
        })

        setOptions(temp)
    }

    useEffect(() => {
        if (user) {
            populateOptions()
            fetchScreenLayout()
        }
    }, [user])

    return (
        <div style={{ width: "120% !important", backgroundColor: "white" }}>
            <Joyride
                steps={steps}
                run={run}
                scrollToFirstStep={true}
                showProgress={false}
                continuous={true}
                callback={handleJoyrideClose}
                stepIndex={stepIndex}
                disableCloseOnEsc
                disableOverlayClose
            />
            <Navbar
                queryOptions={queryOptions}
                setOptions={setOptions}
                querySetter={async (data) => {
                    setSavedQuery(data)
                }}
                newFlag={async (data) => {
                    setFlag(data)
                }}
                classList={["first-step", "thirteenth-step", "second-step"]}
                setRunHelp={async (data) => {
                    setRun(data)
                }}
                updateQuery={queryModified}
                setUpdates={async (data) => {
                    setModifiedState(data)
                }}
                screensOnDisplay={screensOnDisplay}
                setDisplayScreens={async (data) => {
                    setDisplayScreens(data)
                }}
                dailyModal={modalOpen}
                setDailyModal={async (data) => {
                    setModalOpen(data)
                }}
                parameterFlag={async (data) => {
                    setParameterOptions(data)
                }}
            />
            <div
                className='container-fluid'
                style={{ padding: 0, width: "100%" }}>
                <div
                    className='row justify-content-center'
                    style={{ width: "100%", margin: 0, padding: 0 }}>
                    <QueryBuilder
                        classList={["fourth-step", "fifth-step", "sixth-step"]}
                        pieChartsData={async (data) => {
                            setPieChartsData(data)
                        }}
                        compositeSentimentData={async (data) => {
                            setCompositeSentimentData(data)
                        }}
                        newsTableData={async (data) => {
                            setNewsTableData(data)
                        }}
                        stocktwitsTableData={async (data) => {
                            setStocktwitsTableData(data)
                        }}
                        redditTableData={async (data) => {
                            setRedditTableData(data)
                        }}
                        avHicData={async (data) => {
                            setAvHicData(data)
                        }}
                        ibmNewsHicData={async (data) => {
                            setIbmNewsHicData(data)
                        }}
                        stocktwitsHicData={async (data) => {
                            setStocktwitsHicData(data)
                        }}
                        redditHicData={async (data) => {
                            setRedditHicData(data)
                        }}
                        hicData={async (data) => {
                            setHicData(data)
                        }}
                        onChange={(query) => {
                            setQuery(query)
                        }}
                        queryRules={savedQuery}
                        querySetter={async (data) => {
                            setQuery(data)
                        }}
                        viewOnly={false}
                        ticker={async (data) => {
                            setTicker(ticker)
                        }}
                        toggle={async (data) => {
                            setToggle(data)
                        }}
                        DQtoggle={async (data) => {
                            setDQToggle(data)
                        }}
                        parameterFlag={parameterOption}
                        queryName={queryMeta.name}
                        queryVersion={queryMeta.version}
                        querySector={queryMeta.sector}
                        newFlag={newQueryFlag}
                        prevVersion={queryMeta.prevVersion}
                        setNewFlag={async (data) => {
                            setFlag(data)
                        }}
                        Run={run}
                        setUpdates={async (data) => {
                            setModifiedState(data)
                        }}
                        populateOptions={populateOptions}
                    />
                </div>
                {screensOnDisplay.composite ? (
                    <div
                        className='row justify-content-center new-step-two'
                        style={{
                            width: "100% !important",
                            margin: 0,
                            padding: 0
                        }}>
                        <CompositeSent
                            data={compositeSentimentData}
                            newsToggle={newsToggle}
                            setExtWeights={async (data) => {
                                setWeights(data)
                            }}
                        />
                    </div>
                ) : (
                    <div></div>
                )}
                {screensOnDisplay.piecharts ? (
                    <div
                        className='row justify-content-center mt-4'
                        style={{
                            width: "100%",
                            height: "100%",
                            margin: 0,
                            padding: 0
                        }}>
                        <div
                            className='col-md-auto sixth-step'
                            style={{
                                textAlign: "center",
                                marginRight: 0,
                                padding: 0
                            }}>
                            {/* <PieCharts type="News"  values={pieChartsData.ibm_news?pieChartsData.ibm_news.title:{}}/> */}
                            <PieCharts
                                weights={compWeights}
                                type='News'
                                values={
                                    pieChartsData.ibm_news
                                        ? newsToggle === "title"
                                            ? pieChartsData.ibm_news.title
                                            : pieChartsData.ibm_news.text
                                        : {}
                                }
                            />
                        </div>
                        <div
                            className='col-md-auto'
                            style={{ textAlign: "center", padding: 0 }}>
                            <PieCharts
                                weights={compWeights}
                                type='Stocktwits'
                                values={
                                    pieChartsData.stocktwits
                                        ? pieChartsData.stocktwits
                                        : {}
                                }
                            />
                        </div>
                        <div
                            className='col-md-auto'
                            style={{
                                textAlign: "center",
                                marginRight: 0,
                                padding: 0,
                                pointerEvents: "none"
                            }}>
                            <PieCharts
                                weights={compWeights}
                                type='X-Twitter'
                                values={{}}
                            />
                        </div>
                        <div
                            className='col-md-auto'
                            style={{
                                textAlign: "center",
                                marginRight: 0,
                                padding: 0,
                                pointerEvents: "none"
                            }}>
                            <PieCharts
                                weights={compWeights}
                                type='Chatroom'
                                values={{}}
                            />
                        </div>

                        <div
                            className='col-md-auto'
                            style={{
                                textAlign: "center",
                                marginRight: 0,
                                padding: 0
                            }}>
                            <PieCharts
                                type='Reddit'
                                values={
                                    pieChartsData.reddit
                                        ? pieChartsData.reddit
                                        : {}
                                }
                            />
                        </div>
                        <div
                            className='col-md-auto'
                            style={{
                                textAlign: "center",
                                padding: 0,
                                pointerEvents: "none"
                            }}>
                            <PieCharts
                                weights={compWeights}
                                type='Trends'
                                values={{}}
                            />
                        </div>
                        <div
                            className='col-md-auto'
                            style={{ textAlign: "center", padding: 0 }}>
                            <PieCharts
                                weights={compWeights}
                                type='Third Party'
                                values={
                                    pieChartsData.third_party
                                        ? pieChartsData.third_party
                                        : {}
                                }
                            />
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}

                <hr></hr>
                {screensOnDisplay.graph ? (
                    <div className='seventh-step'>
                        <Graphs
                            avData={avHicData}
                            newsData={ibmNewsHicData}
                            stocktwitsData={stocktwitsHicData}
                            redditData={redditHicData}
                            data={hicData}
                            newsToggle={newsToggle}
                            query={savedQuery}
                        />
                    </div>
                ) : (
                    <div></div>
                )}

                <hr></hr>
                {screensOnDisplay.tables ? (
                    <div>
                        <Tables
                            newsData={newsTableData}
                            Run={run}
                            stocktwitsData={stocktwitsTableData}
                            DQToggle={DQToggle}
                            redditData={redditTableData}
                            newsToggle={newsToggle}
                            query={query}
                        />
                    </div>
                ) : (
                    <div></div>
                )}
                <Footer></Footer>
            </div>

            <FContainer
                className='new-step-three'
                styles={{
                    margin: 0,
                    top: "auto",
                    right: 20,
                    bottom: 20,
                    left: "auto",
                    position: "fixed"
                }}>
                <FButton
                    tooltip='Click to open Daily Graphs'
                    // icon={}
                    styles={{
                        backgroundColor: "#f9ce1b",
                        color: "#3d3d3d",
                        padding: 2,
                        boxShadow: "3px 4px rgba(203,203,203,0.6)"
                    }}
                    rotate={false}
                    onClick={() =>
                        setModalOpen(() => {
                            return true
                        })
                    }>
                    <CandlestickChartIcon fontSize='large' />
                </FButton>
            </FContainer>
        </div>
    )
}

export default OurApp
