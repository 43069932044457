import React, { Component, useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import {Box, TextField, Grid, Typography, Button } from "@mui/material";
// import { Button } from 'bootstrap';

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    marginTop:"2%"
  },
  border: {
    borderBottom: "4px solid black",
    width: "100%"
  },
  content: {
    // paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontWeight: 500,
    fontSize: 20,
    color: "black"
  }
}));

let  weights = {
  Credibility:{
    authorPatreonFlair:0, 
    // distinguished:0.142, // 10% of the 70% is 14.2%
    // authorPremium:0.4286, //30% of 70% is 42.86
    // Karma:0.4286
    distinguished:0.2, // 10% of the 70% is 14.2%
    authorPremium:0.4, //30% of 70% is 42.86
    Karma:0.4
  },
  Usefulness:{
       score:0.5, //50% of 30%
       stickied:0.35,
       totalAwardsRecieved: 0.15 //
  }
}

let dq_total_weights={
  Credibility:0.7,
  Usefulness:0.3,
  // Completeness:0.1,

}



const DividerWithText = ( text ) => {
    const classes = useStyles();
    return (
     <div className={classes.container}>
       <div className={classes.border} />
       <span className={classes.content}>{text}</span>
       <div className={classes.border} />
     </div>
    );
   };





const DQRedditHomepage =(props)=>{

const [DQWeights, setDQWeights] = useState(weights)
const [DQParamWeights, setDQParamWeights] = useState(dq_total_weights)


const changeWeights = (section, key, value) =>{
    const updatedValue = {[key]: (value?parseFloat(value):0)}
    setDQWeights((prevState)=>{
      return {...prevState, [section]:{
      ...prevState[section], ...updatedValue
    }  
     }})
}


const changeParamWeights = (key, value) =>{
  const updatedValue = {key:value}
  setDQParamWeights((prevValue)=>{return {...prevValue, ...updatedValue  
   }})
}
const resetWeights = ()=>{
  setDQWeights((prevState)=>{ return weights})
}
useEffect(()=>{
props.setWeights(DQWeights)
},[DQWeights])

return (
        <>
        <div className='container-fluid' style={{ backgroundColor:"white", }}>
            <div className='row w-100 justify-content-center' >
              <Typography variant='h4' align='center'>Reddit Data Quality Weights</Typography>
              {DividerWithText("Creditibility{"+ dq_total_weights['Credibility']+"}")}
              <Box style={{textAlign:"center", margin:"auto"}}> 
                <Grid container justifyContent="space-between" spacing={0} style={{width:"100%", backgroundColor:"whitesmoke", marginTop:"1%"}}>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Distinguished</Typography>
                    <TextField type="number" size='small'  varient="outlined" label="% weightage"
                     value = {DQWeights.Credibility.distinguished} onChange={(event)=>{changeWeights("Credibility", "User_follower", event.target.value)}}/>
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Premium Author</Typography>
                    <TextField type="number" size='small' varient="outlined" label="% weightage"
                     value = {DQWeights.Credibility.authorPremium} onChange={(event)=>{changeWeights("Credibility", "User_classification", event.target.value)}} />
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Karma Points</Typography>
                    <TextField type="number" size='small' varient="outlined" label="% weightage" 
                     value = {DQWeights.Credibility.Karma} onChange={(event)=>{changeWeights("Credibility", "User_join_date", event.target.value)}}/>
                  </Grid>

                </Grid>
              </Box>
              {DividerWithText("Usefulness{"+ dq_total_weights["Usefulness"]+"}")}
              <Box style={{textAlign:"center", margin:"auto"}}> 
                <Grid container justifyContent="space-between" spacing={0} style={{width:"100%", backgroundColor:"whitesmoke", marginTop:"1%"}}>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> {"Score (upvotes - downvotes)"}</Typography>
                    <TextField type="number" size='small'  varient="outlined" label="% weightage" 
                     value = {DQWeights.Usefulness.score} onChange={(event)=>{changeWeights("Usefulness", "likes", event.target.value)}}/>
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Stickied</Typography>
                    <TextField type="number" size='small' varient="outlined" label="% weightage" 
                     value = {DQWeights.Usefulness.stickied} onChange={(event)=>{changeWeights("Usefulness", "ST_Sentiment", event.target.value)}}/>
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Awards Received</Typography>
                    <TextField type="number" size='small' varient="outlined" label="% weightage" 
                     value = {DQWeights.Usefulness.totalAwardsRecieved} onChange={(event)=>{changeWeights("Weights", "likes_max", event.target.value)}}/>
                  </Grid>
                </Grid>
              </Box>
              {/* {DividerWithText("Completeness{"+dq_total_weights["Completeness"]+"}")}
              <Box  style={{textAlign:"center", margin:"auto"}}> 
                <Grid container justifyContent="space-between" spacing={0} style={{width:"100%", backgroundColor:"whitesmoke", marginTop:"1%"}}>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Character Length 20</Typography>
                    <TextField type="number" size='small'  varient="outlined" label="% weightage"
                     value = {DQWeights.Completeness.Body_20} onChange={(event)=>{changeWeights("Completeness","Body_20", event.target.value)}} />
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Character Length 140</Typography>
                    <TextField type="number" size='small'  varient="outlined" label="% weightage"
                     value = {DQWeights.Completeness.Body_140} onChange={(event)=>{changeWeights("Completeness","Body_140", event.target.value)}} />
                  </Grid>
                  <Grid className='mt-1 mb-2' md={3}>
                    <Typography> Character Length 1000</Typography>
                    <TextField type="number" size='small'  varient="outlined" label="% weightage"
                     value = {DQWeights.Completeness.Body_1000} onChange={(event)=>{changeWeights("Completeness","Body_1000", event.target.value)}} />
                  </Grid>
                </Grid>
              </Box> */}
              <div className='row mt-2' style={{textAlign:"end", width:"auto"}}>
                <Button 
                className="col-md-2"  variant="contained" onClick={()=>{resetWeights()}}
                style={{ backgroundColor: "#034ea2", color: "white", marginBottom: 5, fontWeight: 400, fontSize: 20, borderRadius: 10, textTransform: "capitalize" }}
                                
                            >Reset</Button>
              </div>


            </div>
            
        </div>
        </>
    )
}


export default DQRedditHomepage;